<template>
  <div>
    <v-btn
      class="top-button"
      color="success"
      @click="
        !customers.some((c) => c.new)
          ? customers.unshift({ new: true })
          : (customers = customers.filter((c) => !c.new));
        total += 1;
      "
      ><v-icon class="mr-2">add</v-icon>Neuer Kunde</v-btn
    >
    <v-btn
      class="mr-2 top-button"
      @click="sync"
      :disabled="
        !$store.getters['profile/me'] ||
          !$store.getters['profile/me'].lexoffice_connected
      "
      :loading="syncing"
      ><v-icon class="mr-2">cloud_download</v-icon>Kunden von Lexoffice
      importieren</v-btn
    >
    <h1>Kunden</h1>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :headers="[
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Stundensatz',
          align: 'start',
          sortable: true,
          value: 'hourly_rate',
        },
        {
          text: 'Offene Arbeitszeit',
          align: 'start',
          sortable: true,
          value: 'duration',
        },
        {
          text: 'Offene Einnahmen',
          align: 'end',
          sortable: true,
          value: 'earnings',
        },
        {
          text: '',
          align: 'end',
          sortable: true,
          value: 'actions',
        },
      ]"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      :items="customers"
      :loading="loading"
      class="elevation-1 customers"
    >
      <template v-slot:top>
        <v-switch
          v-model="include_archived"
          :true-value="1"
          :false-value="0"
          label="Archivierte Kunden anzeigen"
          class="pt-3 pl-3"
        ></v-switch>
      </template>
      <template v-slot:item.name="props">
        <v-tooltip bottom v-if="props.item.archived">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" dark v-bind="attrs" v-on="on" class="pr-3">
              visibility_off
            </v-icon>
          </template>
          <span>Archiviert</span>
        </v-tooltip>
        <span>
          <img
            v-if="
              props.item.lx_id &&
                $store.getters['profile/me'] &&
                $store.getters['profile/me'].lexoffice_connected
            "
            class="inline-lexoffice-icon"
            height="20"
            :src="require('@/assets/lexoffice-icon.png')"
            alt="Lexoffice-Kunde"
          />
        </span>
        <div class="editable" style="display: inline-block">
          <input
            :style="{ color: props.item.color, width: '23em' }"
            placeholder="Name"
            type="text"
            :class="{ error: !props.item.name }"
            v-model="props.item.name"
            :disabled="props.item.lx_id"
            @keyup.enter="updateCustomer(props.item)"
            @blur="props.item.new ? null : updateCustomer(props.item)"
          />
        </div>
        <v-menu
          :close-on-content-click="false"
          @input="temporaryColor = props.item.color"
        >
          <template v-slot:activator="{ on }">
            <v-btn small icon v-on="on"
              ><v-icon :color="props.item.color">palette</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                dot-size="50"
                hide-canvas
                hide-inputs
                hide-mode-switch
                hide-sliders
                show-swatches
                swatches-max-height="174"
                v-model="temporaryColor"
                flat
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="
                  props.item.color = temporaryColor;
                  updateCustomer(props.item);
                "
                >Übernehmen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.duration="props">
        <router-link
          v-if="props.item.duration"
          :to="{
            name: 'Tasks',
            query: { customer_id: props.item.id },
          }"
          >{{ formatSeconds(props.item.duration) }}
        </router-link>
      </template>
      <template v-slot:item.earnings="props">
        <router-link
          v-if="props.item.earnings"
          :to="{
            name: 'Tasks',
            query: { customer_id: props.item.id },
          }"
          >{{ formatEarnings(props.item.earnings) }} €
        </router-link>
      </template>
      <template v-slot:item.hourly_rate="props">
        <div :class="{ editable: true, 'editable--permanent': props.item.new }">
          <input
            :style="{ color: props.item.hourly_rate ? 'null' : 'grey' }"
            placeholder="0"
            style="width: 6em;"
            type="text"
            v-model="props.item.hourly_rate"
            @keyup.enter="updateCustomer(props.item)"
            @blur="props.item.new ? null : updateCustomer(props.item)"
          />
        </div>
      </template>
      <template v-slot:item.actions="props">
        <div class="table-actions">
          <template v-if="props.item.new">
            <v-btn
              icon
              color="success"
              :disabled="!props.item.name"
              @click="createCustomer()"
              ><v-icon>save</v-icon></v-btn
            >
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="!props.item.lx_id || !props.item.earnings"
                  icon
                  v-on="on"
                  @click="invoiceDialogCustomer = props.item"
                  ><v-icon>cloud_upload</v-icon></v-btn
                >
              </template>
              <span>Rechnung in Lexoffice erstellen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="!props.item.lx_id"
                  icon
                  target="_blank"
                  v-on="on"
                  @click="
                    openUrl(
                      'https://app.lexoffice.de/customer/#/' +
                        props.item.lx_id +
                        '/turnover'
                    )
                  "
                  ><v-icon>open_in_new</v-icon></v-btn
                >
              </template>
              <span>Kunde in Lexoffice öffnen</span>
            </v-tooltip>
            <v-btn icon @click="deleteCustomer(props.item)"
              ><v-icon>delete</v-icon></v-btn
            >
          </template>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      :value="invoiceDialogCustomer !== null"
      width="500"
      @click:outside="invoiceDialogCustomer = null"
    >
      <InvoiceCreation
        :customer="invoiceDialogCustomer"
        @success="
          load();
          invoiceDialogCustomer = null;
        "
      ></InvoiceCreation>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.customers {
  a {
    text-decoration: none;
  }
}
.editable {
  @media (max-width: 690px) {
    input {
      text-align: right;
    }
  }
  &.error {
    border: 1px solid #ff5252;
  }
}
.top-button {
  float: right;
  @media (max-width: 690px) {
    float: none;
    margin-bottom: 0.5rem;
    width: 100%;
  }
}
</style>

<script>
export default {
  components: {
    InvoiceCreation: () =>
      import(
        /* webpackChunkName: "InvoiceCreation" */ '@/components/InvoiceCreation.vue'
      ),
  },
  data() {
    return {
      temporaryColor: '',
      invoiceDialogCustomer: null,
      include_archived: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ['earnings'],
        sortDesc: [true],
      },
      syncing: false,
      loading: true,
      customers: [],
      total: 0,
    };
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
    current_track: {
      deep: true,
      handler(track, old_track) {
        if (track.id !== old_track.id) {
          this.load();
        }
      },
    },
    include_archived() {
      this.load();
    },
  },
  mounted() {
    this.customers = this.$store.getters['customers/list'];
    this.total = this.$store.getters['customers/listTotal'];
  },
  computed: {
    current_track() {
      return this.$store.getters['tracks/current'];
    },
  },
  methods: {
    createCustomer() {
      this.$store
        .dispatch('customers/add', {
          ...this.customers.find((c) => c.new),
          type: 'customers',
        })
        .then(() => {
          this.load();
        })
        .catch((e) => {
          if (e.response) {
            for (const error of e.response.data.errors) {
              this.$store.dispatch('alerts/error', error.detail);
            }
          }
        });
    },
    updateCustomer(customer) {
      if (customer.new) {
        this.createCustomer();
      } else {
        this.$store
          .dispatch('customers/update', customer)
          .then(() => {
            this.$store.dispatch('alerts/success', 'Kunde gespeichert');
            this.load();
          })
          .catch((e) => {
            if (e.response) {
              for (const error of e.response.data.errors) {
                this.$store.dispatch('alerts/error', error.detail);
              }
            }
          });
      }
    },
    deleteCustomer(customer) {
      this.$swal
        .fire({
          title: customer.name + ' löschen?',
          text:
            'Alle zugewiesenen Zeiteinträge werden gelöscht. Lexoffice-Kunden bleiben unberührt.',
          showCancelButton: true,
          confirmButtonText: `Löschen`,
          cancelButtonText: `Abbrechen`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('customers/destroy', customer.id)
              .then(() => {
                this.load();
              })
              .catch(() => {
                this.$store.dispatch(
                  'alerts/error',
                  'Kunde konnte nicht gelöscht werden.'
                );
              });
          }
        });
    },
    load() {
      this.loading = true;
      this.$store
        .dispatch('customers/list', {
          page: { number: this.options.page, size: this.options.itemsPerPage },
          sort: this.options.sortBy.length
            ? (this.options.sortDesc[0] ? '-' : '') +
              this.options.sortBy.join(',')
            : null,
          filter: {
            with_hours: true,
            include_archived: this.include_archived,
          },
        })
        .then(() => {
          this.customers = this.$store.getters['customers/list'];
          this.total = this.$store.getters['customers/listTotal'];
          this.loading = false;
        });
    },
    update(customer) {
      customer.hourly_rate = parseInt(customer.hourly_rate);
      this.$store.dispatch('customers/update', customer).then(() => {
        this.$store.dispatch('alerts/success', 'Gespeichert');
      });
    },
    sync() {
      this.syncing = true;
      this.$store
        .dispatch('customers/sync')
        .then(() => {
          this.$store.dispatch('customers/list');

          this.$store.dispatch(
            'alerts/success',
            'Synchronisierung abgeschlossen.'
          );
          this.load();
          this.syncing = false;
        })
        .catch((e) => {
          console.error(e);
          this.syncing = false;
          if (e.response && e.response.status === 502) {
            this.$store.dispatch(
              'alerts/error',
              'Synchronisierung fehlgeschlagen. Bitte überprüfen Sie in den Einstellungen ihren Api-Schlüssel!'
            );
          } else {
            this.$store.dispatch(
              'alerts/error',
              'Synchronisierung fehlgeschlagen!'
            );
          }
        });
    },
  },
};
</script>
