var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"top-button",attrs:{"color":"success"},on:{"click":function($event){!_vm.customers.some(function (c) { return c.new; })
        ? _vm.customers.unshift({ new: true })
        : (_vm.customers = _vm.customers.filter(function (c) { return !c.new; }));
      _vm.total += 1;}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add")]),_vm._v("Neuer Kunde")],1),_c('v-btn',{staticClass:"mr-2 top-button",attrs:{"disabled":!_vm.$store.getters['profile/me'] ||
        !_vm.$store.getters['profile/me'].lexoffice_connected,"loading":_vm.syncing},on:{"click":_vm.sync}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("cloud_download")]),_vm._v("Kunden von Lexoffice importieren")],1),_c('h1',[_vm._v("Kunden")]),_c('v-data-table',{staticClass:"elevation-1 customers",attrs:{"footer-props":{
      'items-per-page-options': [10, 50, 100],
    },"headers":[
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Stundensatz',
        align: 'start',
        sortable: true,
        value: 'hourly_rate',
      },
      {
        text: 'Offene Arbeitszeit',
        align: 'start',
        sortable: true,
        value: 'duration',
      },
      {
        text: 'Offene Einnahmen',
        align: 'end',
        sortable: true,
        value: 'earnings',
      },
      {
        text: '',
        align: 'end',
        sortable: true,
        value: 'actions',
      } ],"options":_vm.options,"server-items-length":_vm.total,"item-key":"id","items":_vm.customers,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pt-3 pl-3",attrs:{"true-value":1,"false-value":0,"label":"Archivierte Kunden anzeigen"},model:{value:(_vm.include_archived),callback:function ($$v) {_vm.include_archived=$$v},expression:"include_archived"}})]},proxy:true},{key:"item.name",fn:function(props){return [(props.item.archived)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-3",attrs:{"color":"error","dark":""}},'v-icon',attrs,false),on),[_vm._v(" visibility_off ")])]}}],null,true)},[_c('span',[_vm._v("Archiviert")])]):_vm._e(),_c('span',[(
            props.item.lx_id &&
              _vm.$store.getters['profile/me'] &&
              _vm.$store.getters['profile/me'].lexoffice_connected
          )?_c('img',{staticClass:"inline-lexoffice-icon",attrs:{"height":"20","src":require('@/assets/lexoffice-icon.png'),"alt":"Lexoffice-Kunde"}}):_vm._e()]),_c('div',{staticClass:"editable",staticStyle:{"display":"inline-block"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.item.name),expression:"props.item.name"}],class:{ error: !props.item.name },style:({ color: props.item.color, width: '23em' }),attrs:{"placeholder":"Name","type":"text","disabled":props.item.lx_id},domProps:{"value":(props.item.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateCustomer(props.item)},"blur":function($event){props.item.new ? null : _vm.updateCustomer(props.item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(props.item, "name", $event.target.value)}}})]),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){_vm.temporaryColor = props.item.color}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":props.item.color}},[_vm._v("palette")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"dot-size":"50","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","hide-sliders":"","show-swatches":"","swatches-max-height":"174","flat":""},model:{value:(_vm.temporaryColor),callback:function ($$v) {_vm.temporaryColor=$$v},expression:"temporaryColor"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){props.item.color = _vm.temporaryColor;
                _vm.updateCustomer(props.item);}}},[_vm._v("Übernehmen")])],1)],1)],1)]}},{key:"item.duration",fn:function(props){return [(props.item.duration)?_c('router-link',{attrs:{"to":{
          name: 'Tasks',
          query: { customer_id: props.item.id },
        }}},[_vm._v(_vm._s(_vm.formatSeconds(props.item.duration))+" ")]):_vm._e()]}},{key:"item.earnings",fn:function(props){return [(props.item.earnings)?_c('router-link',{attrs:{"to":{
          name: 'Tasks',
          query: { customer_id: props.item.id },
        }}},[_vm._v(_vm._s(_vm.formatEarnings(props.item.earnings))+" € ")]):_vm._e()]}},{key:"item.hourly_rate",fn:function(props){return [_c('div',{class:{ editable: true, 'editable--permanent': props.item.new }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.item.hourly_rate),expression:"props.item.hourly_rate"}],staticStyle:{"width":"6em"},style:({ color: props.item.hourly_rate ? 'null' : 'grey' }),attrs:{"placeholder":"0","type":"text"},domProps:{"value":(props.item.hourly_rate)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateCustomer(props.item)},"blur":function($event){props.item.new ? null : _vm.updateCustomer(props.item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(props.item, "hourly_rate", $event.target.value)}}})])]}},{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"table-actions"},[(props.item.new)?[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!props.item.name},on:{"click":function($event){return _vm.createCustomer()}}},[_c('v-icon',[_vm._v("save")])],1)]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!props.item.lx_id || !props.item.earnings,"icon":""},on:{"click":function($event){_vm.invoiceDialogCustomer = props.item}}},on),[_c('v-icon',[_vm._v("cloud_upload")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechnung in Lexoffice erstellen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!props.item.lx_id,"icon":"","target":"_blank"},on:{"click":function($event){return _vm.openUrl(
                    'https://app.lexoffice.de/customer/#/' +
                      props.item.lx_id +
                      '/turnover'
                  )}}},on),[_c('v-icon',[_vm._v("open_in_new")])],1)]}}],null,true)},[_c('span',[_vm._v("Kunde in Lexoffice öffnen")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteCustomer(props.item)}}},[_c('v-icon',[_vm._v("delete")])],1)]],2)]}}])}),_c('v-dialog',{attrs:{"value":_vm.invoiceDialogCustomer !== null,"width":"500"},on:{"click:outside":function($event){_vm.invoiceDialogCustomer = null}}},[_c('InvoiceCreation',{attrs:{"customer":_vm.invoiceDialogCustomer},on:{"success":function($event){_vm.load();
        _vm.invoiceDialogCustomer = null;}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }